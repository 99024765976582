<template>
  <master-layout>

    <h2>Itt vagy épp</h2>
    <ion-card>
    <WineryCardLargeIllustration
      v-if="winery"
      :winery="winery"
      :checkinDate="null"
    />
    </ion-card>

    <div v-if="winery">
      <div v-if="winery.GeolocationConfirmation">
        <ion-button v-if="!myPositionConfirmed" shape="round" expand="block" color="primary" @click="myPositionPressed=!myPositionPressed">Tartózkodási hely megerősítése</ion-button>
        <ion-button v-else shape="round" expand="block" color="primary" disabled>Tartózkodási hely megerősítése</ion-button>
      </div>
      <ion-button v-if="myPositionConfirmed || !winery.GeolocationConfirmation" shape="round" expand="block" color="primary" @click="saveCheckin()">Becsekkolás mentése</ion-button>
      <ion-button v-else shape="round" expand="block" color="primary" disabled>Becsekkolás mentése</ion-button>
    </div>
    <ion-button expand="block" fill="transparent" color="secondary" @click="cancelCheckin()">Mégsem</ion-button>
    <div class="bottomSpacer"></div>

    <MyPosition
     :myPositionPressed="myPositionPressed"
      @sendGeolocationThinking="sendGeolocationThinking"
      @myPositionFound="myPositionFound"
    />

  </master-layout>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import {
  IonButton, IonCard, loadingController
} from '@ionic/vue'
import WineryCardLargeIllustration from '@/components/WineryCardLargeIllustration.vue'
import MyPosition from '@/components/MyPosition.vue'

export default {
  name: 'CheckinConfirm',
  components: {
    IonButton, IonCard,
    WineryCardLargeIllustration, MyPosition
  },
  data () {
    return {
      winery: null,
      myPositionConfirmed: false,
      myPositionPressed: false,
      geolocationThinking: false,
      latModifier: 0.00000000000001
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    }),
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory',
    })
  },

  async ionViewWillEnter () {
    this.myPositionConfirmed = false
    this.geolocationThinking = false
    if (this.wineryInMemory) {
      this.winery = this.wineryInMemory
    }
  },

  methods: {
    ...mapActions('wineries', {
      setMyWineriesChanged: 'setMyWineriesChanged',
      setMyWineriesZero: 'setMyWineriesZero',
      setWineryInMemory: 'setWineryInMemory'
    }),

    async saveCheckin () {
      if(!this.authData.token){
        this.$router.push('/checkin/logged-out')
      }else{
        this.presentLoading()
        const checkIns = await axios.get(process.env.VUE_APP_STRAPI_URI + 'checkins?User=' + this.authData.userId)
        const wineriesCheckedIn = checkIns.data
        const alreadyCheckedIn = wineriesCheckedIn.findIndex(x => x.Winery.id === this.winery.id)
        this.setMyWineriesZero(false)
        if (alreadyCheckedIn == -1) {
          const response = await axios.post(process.env.VUE_APP_STRAPI_URI + 'checkins',
            {
              User: this.authData.userId,
              Winery: this.winery.id
            },{
              headers: {
                Authorization:
                'Bearer ' + this.authData.token
              }
            }
          )
          // add checkinId to winery object, then save it in Vuex
          this.winery.checkinId = response.data.id
          this.setMyWineriesChanged(true)
          this.setWineryInMemory(this.winery)
          await this.loading.dismiss()
          this.winery = null
          this.$router.push('/app/rating-stars')
        } else {
          this.setMyWineriesChanged(false)
          this.setWineryInMemory(null)
          await this.loading.dismiss()
          alert('Ennél a borászatnál már becsekkoltál!')
          this.$router.push('/boraszatok')
        }
      }
    },

    cancelCheckin () {
      this.setWineryInMemory(null)
      this.setMyWineriesChanged(false)
      this.$router.push('/checkin')
    },

    sendGeolocationThinking (payload) {
      this.geolocationThinking = payload // true or false
    },

    myPositionFound (pos) {
      this.geolocationThinking = false
      let myDistance = this.distanceWinery(pos.coords.latitude, pos.coords.longitude)
      if(myDistance < 1) {
        alert('Örülünk, hogy újabb borélménnyel gazdagodtál!')
        this.myPositionConfirmed = true
      } else {
        alert('Jelenleg ' + myDistance + ' km távolságra vagy a borászattól. Csak akkor tudsz becsekkolni, ha 1 km-es körön belül tartózkodsz.')
      }
    },

    distanceWinery (Lat, Lng) {
      if(this.winery.Addresses && this.winery.Addresses.length > 0){
        let distances = []
        for (let i in this.winery.Addresses) {
          distances.push(this.distance(Lat, Lng, this.winery.Addresses[i].x, this.winery.Addresses[i].y, 'K'))
        }
        distances.sort(function(a, b){return a - b})
        let dist = distances[0]
        return Math.round(dist*100)/100
      } else {
        return 'ismeretlen'
      }
    },

    distance (lat1, lon1, lat2, lon2, unit) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0
      }
      else {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
        return dist
      }
    },

    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
      await this.loading.present()
    }
  }
}
</script>

<style scoped>
ion-card-title{
  font-family: Alphabet;
  font-size: 21px !important;
  color: #333333;
}
h3{
  font-family: Alphabet;
  font-size: 16px !important;
}
ion-button{
  margin: 15px 20px 0px 20px;
}
</style>
